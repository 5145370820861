/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ModalUpdateConnection.vue?vue&type=template&id=5cb87da0&scoped=true&"
import script from "./ModalUpdateConnection.vue?vue&type=script&lang=js&"
export * from "./ModalUpdateConnection.vue?vue&type=script&lang=js&"
import style0 from "./ModalUpdateConnection.vue?vue&type=style&index=0&id=5cb87da0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cb87da0",
  null
  
)

export default component.exports